<template>
  <v-row class="mr-0" id="step2-right-page">
    <v-col cols="12" class="pb-1 pt-1">
      <v-text-field id="search-address" hide-details="auto" placeholder="Address Line 1"  v-model="model.address1"></v-text-field>
    </v-col>
    <v-col cols="6" class="pb-1">
      <v-text-field class="pt-1" placeholder="Suburb" hide-details="auto" id="suburb" v-model="model.suburb"></v-text-field>
    </v-col>
    <v-col cols="3" class="pb-1">
      <v-text-field class="pt-1" placeholder="State" hide-details="auto" id="state" v-model="model.state"></v-text-field>
    </v-col>
    <v-col cols="3" class="pb-1">
      <v-text-field class="pt-1" placeholder="Postcode" hide-details="auto" id="postcode" name="postcode" v-model="model.postcode"></v-text-field>
    </v-col>
    <v-col cols="6" class="pb-1">
      <v-text-field placeholder="Country" hide-details="auto" class="pt-0" id="country" v-model="model.country"></v-text-field>
    </v-col>
    <v-col cols="6" class="pb-1">
        <VuePhoneNumberInput class="phone-input"  :no-example="true" :error="model.phoneErr" error-color="#DC0050" color="#82B1FF" valid-color="#0032A0" @update="phoneUpdate($event, 'ph')" v-model="model.phone" default-country-code="AU"></VuePhoneNumberInput></v-col>
    <v-col cols="6" class="pb-1">
      <v-radio-group dense v-model="model.gender"  hide-details="auto" class="pt-0" row>
        <v-radio label="Male" class="mr-2" value="male"></v-radio>
        <v-radio label="Female" class="mr-0" value="female"></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="6" class="pb-1">
        <VuePhoneNumberInput class="mobile-input" :no-example="true" :error="model.mobileErr" error-color="#DC0050" color="#82B1FF" valid-color="#0032A0" @update="phoneUpdate($event, 'mob')" v-model="model.mobile" default-country-code="AU"></VuePhoneNumberInput>
    </v-col>
    <v-col cols="6" class="pb-1 pt-1">
      <v-menu v-model="didYouMeanOpen" offset-y :disabled="!didYouMeanOpen" rounded="b-xl">
          <template v-slot:activator="{ on, attrs }">
              <v-text-field placeholder="Email" hide-details="auto" v-model="model.email" @input="experianEmailSuggest()"
                :error-messages="$helpers.errorMsg('email', valid.model.email, 'Address or Email')" v-bind="attrs" v-on="on"
                @blur="experianEmailSuggest"></v-text-field>
          </template>
          <v-list>
              <v-list-item-title class="font-weight-bold pl-2">Did you mean:</v-list-item-title>
              <v-list-item @click="model.email = item;didYouMeanOpen=false" class="pa-0 min-h-auto my-1" v-for="(item, index) in didYouMeanLists" :key="index">
                  <v-list-item-title class="secondary--text font-weight-bold pl-3">{{ item }}</v-list-item-title>
              </v-list-item>
          </v-list>
      </v-menu>
    </v-col>
    <v-col cols="6" class="pb-1 pt-1">
      <v-switch
          v-model="model.subscribe_news" dense class="pt-0"
          label="Subscribe to Light News"
          hide-details
      ></v-switch>
    </v-col>
    <v-col cols="6" class="pb-1 pt-8 primary--text font-weight-bold d-flex align-center">
      <h5 class="primary--text text-strong font-weight-bold">Who is this gift from</h5>
    </v-col>
    <v-col cols="6" class="pb-1 pt-8">
      <v-switch class="mt-0" dense
        v-model="model.businessDonation"
        label="Business Donation"
        hide-details
      ></v-switch>
    </v-col>
    <v-col cols="6" class="pb-1 pt-0">
      <v-text-field label="Receipt Name" @input="model.receiptTouched = true" hide-details="auto" v-model.trim="model.receipt_name"></v-text-field>     
    </v-col>
    <v-col cols="6" class="pb-1 pt-0">
      <v-text-field  label="On Air Read Out Name" @input="model.onairOutTouched = true" hide-details="auto" v-model.trim="model.donor_infos.first_name"></v-text-field> 
    </v-col>
    <v-col cols="12" v-if="model.businessDonation" class="pb-1 pt-0">
      <v-autocomplete ref="businessname" v-model="model.business" item-text="name" item-value="sf_id" class="mt-3"
        hide-details="auto" label="" placeholder="Business Name" :search-input.sync="businessSearch"
        :items="businessLists" :menu-props="{ closeOnClick: true,  closeOnContentClick: true }"
        hide-no-data return-object @blur="model.sf_account_id = model.business.sf_id" @keydown.tab.prevent="tabClickBD">
        <template slot="append" v-if="businessListLoading">
          <v-progress-circular indeterminate color="secondary" size="20" class="mr-3"></v-progress-circular>
        </template>
        <template slot="item" slot-scope="{ item }">
          {{ item.disabled ? 'Choose the business account' : item.name }}
        </template>
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "stepTwo",
  props: ["v"],
  components: {
    VuePhoneNumberInput
  },
  data() {
    return {
      valid: {},
      didYouMeanOpen: false,
      didYouMeanLists: [],
      emailValidLoading: false,
      businessLists: [],
      businessSearch: '',
      initialLoading: false,
      prevValue: {
        selected: null,
        lists: []
      },
      timoutId: { businessAccTimeOut: null, emailTimeOut: null},
      apiSub: false
    }
  },
  computed: {
    ...mapGetters(['businessListLoading']),

    model: {
      get() {
        return this.$store.getters["model"];
      },
      set(newValue) {
        return this.$store.commit("setModel", newValue);
      },
    },
  },
  watch: {
      businessSearch(newVal) {
        if ((!this.model.business || this.model.business.name !== newVal) && newVal) this.getListData(newVal);
      },
      'model.businessLists': function(newVal) {
        if (!this.initialLoading && newVal.length) {
          this.businessLists = this.prevValue.lists = newVal;
          if (!this.model.business) {
            this.model.business = this.businessLists[0];
            this.model.business.disabled = false;
          }
          this.prevValue.selected = this.model.business;
        }
        this.initialLoading =  true;
      },
      'model.business': function(newVal) {
        if (newVal && !newVal.disabled && typeof newVal.disabled == 'boolean') {
          this.prevValue.selected = newVal;
          if(this.$refs && this.$refs.businessname) this.$refs.businessname.blur();
        }
      },
      'model.receipt_name': function(newVal) {
        if (newVal) this.model.receipt_name = this.$filters.titleCase(newVal);
      },
      'model.donor_infos.first_name': function(newVal) {
        if (newVal) this.model.donor_infos.first_name = this.$filters.titleCase(newVal);
      }
  },
  created() {
    this.model = {...{
        address1: "",
        state: "",
        postcode: "",
        donor_infos: {
          first_name: '',
        },
        receipt_name: '',
        gender: "",
        subscribe_news: false,
        countryCode: {
            code: "61",
        },
        phoneErr: false,
        mobileErr: false,
        business: null
      }, ...this.model};
    this.valid = this.v;
  },
  mounted() {
    (document.getElementsByClassName("select-country-container")[0]).style.maxWidth = '90px';
    (document.getElementsByClassName("select-country-container")[0]).style.minWidth = '90px';
    (document.getElementsByClassName("select-country-container")[1]).style.maxWidth = '90px';
    (document.getElementsByClassName("select-country-container")[1]).style.minWidth = '90px';
    (document.getElementsByClassName("country-selector__label")[0]).style.display = 'none';
    (document.getElementsByClassName("country-selector__label")[1]).style.display = 'none';
    (document.getElementsByClassName("country-selector__country-flag")[0]).style.top = '15px';
    (document.getElementsByClassName("country-selector__country-flag")[1]).style.top = '15px';
    (document.getElementsByClassName("country-selector__toggle")[0]).style.top = 'calc(45% - 10px)';
    (document.getElementsByClassName("country-selector__toggle")[1]).style.top = 'calc(45% - 10px)';
    (document.getElementsByClassName("country-selector__input")[0]).style.paddingTop = '0px';
    (document.getElementsByClassName("country-selector__input")[1]).style.paddingTop = '0px';
    (document.getElementsByClassName("country-selector")[0]).getElementsByTagName('label')[0].style.display = 'none';
    (document.getElementsByClassName("select-country-container")[0]).style['min-width'] = '95px';
    const self = this;
    const phoneEl = (document.getElementsByClassName("phone-input")[0]).getElementsByClassName('input-phone-number')[0];
    phoneEl.getElementsByTagName('input')[0].placeholder = phoneEl.getElementsByTagName('label')[0].innerText = 'Phone';
    const mobileEl = (document.getElementsByClassName("mobile-input")[0]).getElementsByClassName('input-phone-number')[0];
    mobileEl.getElementsByTagName('input')[0].placeholder = mobileEl.getElementsByTagName('label')[0].innerText = 'Mobile';

    const searchFieldSelector = $('#search-address');

    // Address Capture options and initialization
    const address = new ContactDataServices.address({
        layout: 'IntuitiveDefault',
        token: window.VUE_APP_EXPERIAN_API_KEY,
        elements: {
            input: searchFieldSelector[0],
            // Hard-coded country if only single country supported.
            countryList: {
                value: 'AUS'
            }
        }
    });
    address.events.on("post-formatting-search", function(item){
      const address = item.address;
      self.model.address1 = `${address[0].addressLine1} ${address[1].addressLine2} ${address[2].addressLine3}`;
      self.model.suburb = address[3].locality;
      self.model.state = address[4].province;
      self.model.postcode = address[5].postalCode;
      self.model.country = address[6].country;
    });
  },
  methods: {
    ...mapActions(["getEmailSuggestion", "searchAccount"]),

    getListData(evt) {
      if (this.timoutId.businessAccTimeOut) {
        clearTimeout(this.timoutId.businessAccTimeOut);
        this.timoutId.businessAccTimeOut = false;
      }
      const self = this;
      this.timoutId.businessAccTimeOut = setTimeout(async () => {
        self.$store.dispatch('cancelPendingRequests');
        self.model.business = {};
        self.model.sf_account_id = self.model.business.name = self.model.business.sf_id = evt;
        self.model.business.disabled = true;
        self.model.businessLists = self.businessLists = [];
        self.model.businessLists.push(self.model.business);
        self.businessLists.push(self.model.business);
        this.searchAccount({name : evt}).then((response) => {
          if (!self.apiSub && response.length) {
            response.unshift(self.model.business);
            self.model.businessLists = self.businessLists = self.prevValue.lists = response;
          } else if (self.apiSub) {
            self.model.business = self.prevValue.selected;
            self.model.businessLists = self.businessLists = self.prevValue.lists;
          }
          self.apiSub = false;
        }).catch(err => {
            self.apiSub = false;
            self.model.business = self.prevValue.selected;
            self.model.businessLists = self.businessLists = self.prevValue.lists;    
        });
        self.timoutId.businessAccTimeOut = false;
      }, 500);
    },
    phoneUpdate(data, field) {
      if (field === 'ph') {
        if (!data.isValid && this.model.phone) {
          this.model.phoneErr = true;
        } else {
          this.model.phoneErr = false;
        }
      } else {
        if (!data.isValid && this.model.mobile) {
          this.model.mobileErr = true;
        } else {
          this.model.mobileErr = false;
        }
      }
    },
    experianEmailSuggest() {
      if (this.timoutId.emailTimeOut) {
        clearTimeout(this.timoutId.emailTimeOut);
        this.timoutId.emailTimeOut = false;
      }
      const self = this;
      this.timoutId.emailTimeOut = setTimeout(async () => {
        self.$store.dispatch('cancelPendingRequests');
        self.didYouMeanOpen = false;
        self.didYouMeanLists = [];
        if (self.valid.model.email.email && self.model.email && !self.emailValidLoading) {
            self.emailValidLoading = true;
            this.getEmailSuggestion(self.model.email).then((resp) => {
                if(resp && resp.result && resp.result.did_you_mean) {
                    self.didYouMeanLists = resp.result.did_you_mean || [];
                    if (resp.result.confidence !== 'verified' && self.didYouMeanLists.length) self.didYouMeanOpen = true;
                }
                self.timoutId.emailTimeOut = false;
                self.emailValidLoading = false;
            }).catch(err => {
                self.timoutId.emailTimeOut = false;
                self.emailValidLoading = false
            });
        }
        if (self.valid.model.email.email && self.model.email && self.didYouMeanLists.length) self.didYouMeanOpen = true;
        self.timoutId.emailTimeOut = false;
      }, 500);
    },
    tabClickBD() {
      this.apiSub = true;
      this.$refs.businessname.isMenuActive = false;
      if (this.model.business.disabled && !this.prevValue.selected.disabled) this.model.business = this.prevValue.selected;
    }
  }
};
</script>
